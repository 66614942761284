import { initializeChakraComponent } from 'Shared/chakra-initialize';
import HomePageSearchHero from './search_hero/search_hero';
import { WPCampaignBanner } from 'Shared/Campaigns/campaign-banner-wp';
import { Box, Flex, Image } from '@chakra-ui/react';

type HomePageHeroProps = {
   type: string;
   prompt: string;
   hasCampaigns: boolean;
   campaignEnabled: boolean;
   heroImagePath: string;
   sizes: {
      intermediate_width: number;
      small_width: number;
      space: number;
      default: number;
      small: number;
      intermediate: number;
   };
};

const HomePageHero = (props: HomePageHeroProps) => {
   const fallbackSearchHero = (
      <Flex
         height={{ base: '177px', md: '333px', lg: '450px' }}
         flexDirection="column"
         justifyContent="center"
         alignItems="center"
         paddingTop={3}
         paddingBottom={{ base: 5, md: 3 }}
         position="relative"
         overflow="hidden"
      >
         <Box as="picture" position="absolute" inset="0">
            <source
               srcSet={`
                  ${props.heroImagePath}-425.avif 425w,
                  ${props.heroImagePath}-768.avif 768w,
                  ${props.heroImagePath}-1440.avif 1440w,
                  ${props.heroImagePath}-2180.avif 2180w,
               `}
               type="image/avif"
            />
            <Image
               src={`${props.heroImagePath}-2180.jpg`}
               srcSet={`
                  ${props.heroImagePath}-425.jpg 425w,
                  ${props.heroImagePath}-768.jpg 768w,
                  ${props.heroImagePath}-1440.jpg 1440w,
                  ${props.heroImagePath}-2180.jpg 2180w,
               `}
               alt={`${props.prompt}`}
               width="100%"
               height="100%"
               objectFit="cover"
               htmlWidth="2180"
               htmlHeight="504"
               // @ts-expect-error: allow `fetchpriority="high"` for performance improvement
               fetchpriority="high"
            />
         </Box>
         <Box
            background="linear-gradient(0deg, rgba(36, 44, 51, 0.5), rgba(36, 44, 51, 0.5))"
            position="absolute"
            inset="0"
         />
         <HomePageSearchHero {...props} />
      </Flex>
   );

   return props.campaignEnabled ? (
      <WPCampaignBanner {...props} fallbackComponent={fallbackSearchHero} />
   ) : (
      fallbackSearchHero
   );
};

initializeChakraComponent('HomePageHero', HomePageHero);
