import { _js } from '@ifixit/localize';
import { useState } from 'react';
import { space, fontFamily, fontSize, breakpoint } from '@ifixit/primitives';
import styled from 'styled-components';
import DocumentHighlight from './document_highlight';
import DocumentLink from './document_link';
import type { Document, FeaturedDocumentsProps } from './featured_documents_types';
import MediaQuery from 'react-responsive';

const MOBILE = `@media only screen and (max-width: ${breakpoint.sm})`;
const TABLET = `@media only screen and (max-width: ${breakpoint.md})`;

const Title = styled.h3`
   font-size: 1.5rem;
   font-weight: bold;
   font-family: ${fontFamily.sansSystem};
   line-height: 1.8125rem;
   color: black;
   font-size: ${fontSize.xl};
   margin-bottom: ${space[4]};
   text-transform: uppercase;

   ${MOBILE} {
      text-align: center;
   }
`;
const DesktopContainer = styled.div`
   justify-content: space-between;
   display: flex;
`;

const MobileContainer = styled.div`
   overflow-x: auto;
   display: block;
`;

const HighlightScroll = styled.div`
   white-space: nowrap;

   ${MOBILE} {
      padding-left: ${space[7]};
   }
`;

const DesktopHighlight = styled(DocumentHighlight)`
   flex: 7;
   margin-right: ${space[7]};

   ${TABLET} {
      flex: 5;
      display: block;
   }
`;

const MobileHighlight = styled(DocumentHighlight)`
   display: inline-block;
   white-space: normal;
   vertical-align: top;
   width: calc(85vw - ${space[7]}*3);
   margin-right: ${space[7]};
`;

const Links = styled.div`
   flex: 4;
   display: block;
`;

const FeaturedDocumentBox = styled.div`
   padding: ${space[7]};
   background-color: #f7f9fa;
   font-size: 1rem;
   font-family: ${fontFamily.sansSystem};
   line-height: 1.5rem;
   text-align: left;

   ${MOBILE} {
      padding: ${space[7]} 0;
   }
`;

function FeaturedDocuments(props: FeaturedDocumentsProps) {
   const [activeLink, setActiveLink] = useState(0);

   const documents: Array<Document> = props.documents;

   if (documents.length == 0) {
      return null;
   }

   return (
      <FeaturedDocumentBox>
         <Title>{props.collection_title}</Title>
         <MediaQuery minWidth={breakpoint.sm}>
            {matches =>
               matches ? (
                  <DesktopContainer>
                     <DesktopHighlight document={documents[activeLink]} />
                     <Links>
                        {documents.map((document: Document, index: number) => (
                           <DocumentLink
                              key={document.url}
                              document={document}
                              active={index == activeLink}
                              onClick={() => setActiveLink(index)}
                           />
                        ))}
                     </Links>
                  </DesktopContainer>
               ) : (
                  <MobileContainer>
                     <HighlightScroll>
                        {documents.map((document: Document) => (
                           <MobileHighlight document={document} key={document.url} />
                        ))}
                     </HighlightScroll>
                  </MobileContainer>
               )
            }
         </MediaQuery>
      </FeaturedDocumentBox>
   );
}

export default FeaturedDocuments;
