import { _js } from '@ifixit/localize';
import { useState, useMemo } from 'react';
import { SearchInput } from 'Shared/search_input';
import { color, fontSize, lineHeight, space, breakpoint, borderRadius } from '@ifixit/primitives';
import styled from 'styled-components';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';
import deviceNavigator from 'Shared/device_navigator';

const MOBILE = `@media only screen and (max-width: ${breakpoint.sm})`;

const heroContentWidth = `
   width: ${breakpoint.sm};
   ${MOBILE} {
      width: 90%;
   }
`;

const heroTextFontSize = `
   font-size: ${fontSize['5xl']};
   line-height: ${lineHeight.tight};

   ${MOBILE} {
      font-size: ${fontSize['3xl']};
   }
`;

const heroTextAlign = `
   text-align: center;
   ${MOBILE} {
      text-align: left;
   }
`;

const HeroTextContainer = styled.h1`
   ${heroTextFontSize};
   ${heroTextAlign};
   font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
   font-style: normal;
   font-weight: normal;
   padding-bottom: ${space[5]};
   margin: 0;
   color: ${color.white};
   position: relative;
   z-index: 1;
`;

const heroText = _js('Explore a World of Repair');

const SearchWrapper = styled.form`
   ${heroContentWidth};
`;

const StyledSearchInput = styled(SearchInput)`
   height: ${space[8]};
   border-radius: ${borderRadius.md};
   input {
      background-color: white;
   }
   input:focus {
      box-shadow: none;
      border-color: inherit;
   }
`;

const Explore = styled.div`
   display: block;
   margin-top: ${space[4]};
   ${MOBILE} {
      margin-top: ${space[3]};
   }

   font-size: ${fontSize.lg};
   line-height: ${lineHeight.tight};
   ${heroTextAlign};
   color: white;
   opacity: 0.9;
`;

const ExploreLink = styled.a`
   color: white;
   text-decoration: underline;
   cursor: pointer;
`;

// c.f. https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
let autoFocus = !(navigator.platform && /iphone|ipod|ipad/i.test(navigator.platform));

const searchInputProps = {
   autoFocus,
};

const handleSubmit = value => event => {
   trackInPiwikAndGA({
      eventCategory: 'Search',
      eventAction: 'Search - Homepage Hero',
   });
   event.preventDefault();
   document.location = '/Search/' + encodeURIComponent(value);
};

const breakUpExploreCategoriesString = () => {
   /* Translators: %1 and %2 provide the start and end of a link */
   const translatedString = _js('or %1explore categories%2');
   const pieces = /^(.*)%1(.*)%2(.*)$/.exec(translatedString);
   return {
      beforeExplore: pieces && pieces[1],
      exploreCategories: (pieces && pieces[2]) || translatedString.replace(/%\d+/, ''),
      afterExplore: pieces && pieces[3],
   };
};

export default function HomePageSearchHero(props) {
   const [value, setValue] = useState('');
   const { beforeExplore, exploreCategories, afterExplore } = useMemo(
      breakUpExploreCategoriesString
   );
   /* Translators: Prompting the user to search for the phrase %1 */
   const prompt = _js('Try "%1"', props.prompt);
   return (
      <SearchWrapper action="Search" onSubmit={handleSubmit(value)}>
         <HeroTextContainer>{heroText}</HeroTextContainer>
         <StyledSearchInput
            value={value}
            onChange={event => setValue(event.target.value)}
            placeholder={prompt}
            {...searchInputProps}
         />
         <Explore>
            {beforeExplore}
            <ExploreLink href="#" onClick={deviceNavigator}>
               {exploreCategories}
            </ExploreLink>
            {afterExplore}
         </Explore>
      </SearchWrapper>
   );
}
