import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { _js } from '@ifixit/localize';
import {
   Box,
   Flex,
   Heading,
   Image,
   SimpleGrid,
   Button,
   Link,
   Text,
   ChakraProps,
} from '@chakra-ui/react';

interface Partnership {
   name: string;
   logoProps: LogoProps;
}

interface LogoProps {
   logo: string;
   altText: string;
   link: string;
   scale?: number;
}

interface PartnershipProps {
   partners: Partnership[];
}

const PartnershipsDisplay = ({ partners }: PartnershipProps) => (
   <Flex direction="column" mt="16" mb="16" alignItems="center">
      <PartnershipHeading />
      <PartnershipGrid partners={partners} />
      <PartnershipLinkButton />
   </Flex>
);

const PartnershipHeading = () => (
   <Box mx="5" mb="10">
      <Heading textAlign="center" m="auto" mb="6" border="none">
         {_js('Our Allies')}
      </Heading>
      <Text textAlign="center" max-width="700px" fontSize="lg">
         {_js(
            'iFixit works with some manufacturers who are committed to making your stuff easier to fix.'
         )}
      </Text>
   </Box>
);

const PartnershipGrid = ({ partners }: PartnershipProps) => {
   const partnershipLogos = partners.map((partner: Partnership) => (
      <PartnershipLogo key={partner.name} {...partner.logoProps} />
   ));

   return (
      <SimpleGrid
         borderRadius="lg"
         columns={{ base: 2, lg: 4 }}
         spacing="0.5"
         width={{ base: 'fit-content' }}
         mx="0.5"
         mb="10"
      >
         {partnershipLogos}
      </SimpleGrid>
   );
};

const PartnershipLogo = ({ logo, altText, link, scale }: LogoProps) => {
   const transform: ChakraProps = scale ? { transform: `scale(${scale})` } : {};
   return (
      <Link
         href={link}
         _hover={{ boxShadow: '0 0 10px gray', zIndex: 1 }}
         maxHeight="150px"
         maxWidth="320px"
         display="flex"
         justifyContent="center"
         bgColor="#f5f5f5"
      >
         <Image
            objectFit="contain"
            src={logo}
            alt={altText}
            {...transform}
            height="auto"
            maxWidth="100%"
            loading="lazy"
         />
      </Link>
   );
};

const PartnershipLinkButton = () => (
   <Button
      as="a"
      href="/collaborations"
      variant="solid"
      bg="blue.ifixit"
      _hover={{ bg: 'blue.ifixit', boxShadow: '0 0 5px gray' }}
   >
      <Text color="white" fontWeight="700">
         {_js('Learn More')}
      </Text>
   </Button>
);

initializeChakraComponent('PartnershipsDisplay', PartnershipsDisplay);
